/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Language } from '../common/utils';
import { addToCart } from "../../redux/actions/cart";
import { PageHeader, ProductCard, ProductSlider } from '../common';
import { addToWishlist, removeFromWishlist } from '../../redux/actions/wishlist';
import { useParams } from 'react-router-dom';

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const dictionary = Language.getDictionary(); // Get the dictionary for the current language

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    const dispatch = useDispatch();
    const params = useParams();
    const products = useSelector(state => state.products);
    const wishlist = useSelector(state => state.wishlist.data);

    const [product, setProduct] = useState({});
    const [productSize, setProductSize] = useState("");
    const [productColor, setProductColor] = useState("");
    const [productQuantity, setProductQuantity] = useState(1);
    const [productTab, setProductTab] = useState(0);

    const isProductInWishlist = (product) => {
        return wishlist[product.id] != undefined;
    }

    useEffect(() => {
        if (products.data.length > 0) {
            setProduct(products.data.find(elm => elm.id == params.id));
        } 
    }, [params, products]);

    useEffect(() => {
        window.dataLayer.push({
            'event': 'select_item', 'ecommerce': {
                'items': [{
                    'id': product.id,
                    'name': product.title,
                    'price': product.price,
                    'quantity': product.stock
                }]
            }
        });
    }, [product]);

    return (
        <>
            {
                JSON.stringify(product) != "{}" ?
                    <Helmet>
                        <title>{Language.language == "en" ? `Sufyi Embroidery. Hoodie ${product?.title}` : `Sufyi Brodat. Hanorac Brodat ${product?.title}`}</title>
                        <meta name="description" content={product?.description} />
                        <meta property="og:title" content={Language.language == "en" ? `Sufyi Embroidery. Hoodie ${product?.title}` : `Sufyi Brodat. Hanorac Brodat ${product?.title}`} />
                        <meta property="og:description" content={product?.description} />
                        <meta property="og:url" content={`https://sufyi.com/product/${product?.id}`}></meta>
                    </Helmet>
                    :
                    <Helmet>
                        <title>{dictionary['our_product'] || 'Our product'}.</title>
                        <meta name="description" content={dictionary['product_description'] || 'Click to see details about our product!'} />
                    </Helmet>
            }
            <Layout>
                <PageHeader page={`${dictionary['products'] || 'Products'} / ${product?.title}`} />
                {
                    JSON.stringify(product) != "{}" ?
                        <div className={isMobile ? "product__container m-auto" : "product__container m-auto p-5"}>
                            <ProductSlider images={product?.images} />

                            <div className="d-flex flex-column w-100 p-3 pt-0 justify-content-start">
                                <div className='title'>
                                    {Language.language == "en" ? `Hoodie ${product?.title}` : `Hanorac Brodat ${product?.title}`} 
                                </div>

                                <div className='price'>
                                    {
                                        product?.content?.promo > 0 ?
                                            <>
                                                <strike>{product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</strike> <span className="promo"> - {product.content?.promo}%</span> {(Math.floor((product.price - product.price * product.content?.promo / 100) * 100) / 100).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                            </>
                                            :
                                            <>
                                                {product.price.toLocaleString("ro-RO", { style: "currency", currency: "RON" })}
                                            </>
                                    }
                                </div>

                                {
                                    product.stock == 0 ?
                                        <div className='price fs-6'>
                                            {dictionary['out_of_stock'] || 'Product is out of stock for the moment'}
                                        </div>
                                        :
                                        product.stock > 10 ?
                                            <div className='fs-6'>
                                                {dictionary['in_stock'] || 'The product is in stock'}
                                            </div>
                                            :
                                            <div className='price fs-6'>
                                                {dictionary['limited_stock'] || 'The product is in limited stock'}
                                            </div>
                                }

                                <div className='d-flex flex-column justify-content-start gap-4 my-4'>
                                    {
                                        product?.content?.colors && product?.content?.colors.length > 0 ?
                                            <div>
                                                <div className='label'>{dictionary['colors'] || 'Colors'}:</div>
                                                <div className='value colors'>
                                                    {product?.content?.colors.map((value, idx) => (
                                                        <div key={idx} className={(productColor == value ? ' active' : '')}>
                                                            <div className={`color ${value}`}
                                                                onClick={() => {
                                                                    setProductColor(value);
                                                                }}
                                                            >
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    {
                                        product?.content?.sizes && product?.content?.sizes.length > 0 ?
                                            <div>
                                                <div className='label'>{dictionary['sizes'] || 'Sizes'}:</div>
                                                <div className='value sizes'>
                                                    {
                                                        product?.content?.sizes.map((value, idx) => (
                                                            <div key={idx} className={'size' + (productSize == value ? ' active' : '')}
                                                                onClick={() => {
                                                                    setProductSize(value);
                                                                }}
                                                            >
                                                                <div className="value">
                                                                    {value}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>

                                <div className='d-flex flex-row justify-content-between align-items-center gap-2'>
                                    <div className='quantity'>
                                        <div className='button' onClick={() => {
                                            setProductQuantity(productQuantity == 1 ? 1 : (productQuantity - 1));
                                        }
                                        }>
                                            <span class="material-icons-outlined">
                                                remove
                                            </span>
                                        </div>
                                        {productQuantity}
                                        <div className='button' onClick={() => {
                                            setProductQuantity(productQuantity + 1);
                                        }
                                        }>
                                            <span class="material-icons-outlined">
                                                add
                                            </span>
                                        </div>
                                    </div>

                                    <div className="button button--buy" onClick={() => {
                                        if (productColor != "") {
                                            if (productSize != "") {
                                                if (product.stock == 0) {
                                                    alert(dictionary['out_of_stock_alert'] || "The product is out of stock!");
                                                    return;
                                                }

                                                window.dataLayer.push({
                                                    'event': 'add_to_cart', 'ecommerce': {
                                                        'items': [{
                                                            'id': product.id,
                                                            'name': product.title,
                                                            'price': product.price,
                                                            'quantity': productQuantity
                                                        }]
                                                    }
                                                });

                                                dispatch(addToCart(product.id, { color: productColor, size: productSize }, productQuantity));
                                            }
                                            else {
                                                alert(dictionary['select_size_alert'] || "Please select a size!");
                                                return;
                                            }
                                        }
                                        else {
                                            alert(dictionary['select_color_alert'] || "Please select a color!");
                                            return;
                                        }
                                    }}>
                                        {dictionary['add_to_cart'] || 'Add to cart'}
                                    </div>

                                    <div className={isProductInWishlist(product) ? "button--rev-active" : "button--reverse"} onClick={() => {
                                        if (isProductInWishlist(product)) {
                                            dispatch(removeFromWishlist(product.id, { color: productColor, size: productSize }));
                                        }
                                        else {
                                            dispatch(addToWishlist(product.id, { color: productColor, size: productSize }));
                                        }
                                    }}>
                                        <img className="my-auto" src={require("../../assets/wishlist.png").default} alt={dictionary['wishlist'] || 'Wishlist'} />
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    {dictionary['note'] || 'NOTE'}: {dictionary['production_shipping_time'] || 'We need 10 to 14 days to make and ship existing designs'}
                                    <br />
                                    {dictionary['production_time'] || '7 to 10 days for production'}
                                    <br />
                                    {dictionary['shipping_time'] || '2 to 4 days for shipping'}
                                </div>
                                <div className={`mb-1 card ${productTab != 1 ? 'rounded-pill' : 'rounded-6'}`} onClick={() => setProductTab(productTab != 1 ? 1 : 0)}>
                                    <div className='bg-black text-white w-100 p-3 d-flex flex-row justify-content-between rounded-pill'>
                                        <div>{dictionary['description'] || 'Description'}</div>

                                        <div class="pointer material-icons-outlined">

                                            {
                                                productTab == 1 ?
                                                    "keyboard_arrow_up"
                                                    :
                                                    "keyboard_arrow_down"
                                            }
                                        </div>
                                    </div>

                                    {
                                        productTab == 1 &&
                                        <div className='p-3'>{product.description.split(/\n/).map(line => <React.Fragment key={line}>{line}<br /></React.Fragment>)}</div>
                                    }
                                </div>

                                <div className={`mb-1 card ${productTab != 2 ? 'rounded-pill' : 'rounded-6'}`} onClick={() => setProductTab(productTab != 2 ? 2 : 0)}>
                                    <div className='bg-black text-white w-100 p-3 d-flex flex-row justify-content-between rounded-pill'>
                                        <div>{dictionary['additional'] || 'Additional'}</div>

                                        <div class="pointer material-icons-outlined">

                                            {
                                                productTab == 2 ?
                                                    "keyboard_arrow_up"
                                                    :
                                                    "keyboard_arrow_down"
                                            }
                                        </div>
                                    </div>

                                    {
                                        product?.content?.colors.length > 0 && product?.content?.sizes.length > 0 && productTab == 2 &&
                                        <div className='p-3'>
                                            <table className="table border">
                                                <tbody>
                                                    <tr>
                                                        <th scope="col">{dictionary['color'] || 'COLOR'}</th>
                                                        <th>{product?.content?.colors.map(value => value.replace(/^./, value[0].toUpperCase()) + " ")}</th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">{dictionary['sizes'] || 'SIZES'}</th>
                                                        <td>{product?.content?.sizes.map(value => value.replace(/^./, value[0].toUpperCase())) + " "}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                <div>
                    <h1 className='text-center bg-black text-light w-fit p-4 pt-2 pb-2 m-auto mb-5'>{dictionary['you_may_also_like'] || 'You may also like'}</h1>
                    <div className="products">
                        {
                            products?.data?.sort((elm1, elm2) => (elm2?.id - elm1?.id)).filter((elm) => elm?.category_id === product?.category_id && elm.id !== product?.id).slice(0, 3).map((value, idx) => (
                                <ProductCard key={idx} product={value} />
                            ))
                        }
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Index;