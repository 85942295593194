import ENDictionary from '../../../dicts/en.json';
import RODictionary from '../../../dicts/ro.json';

import ROFlag from '../../../assets/flags/ro.png';
import ENFlag from '../../../assets/flags/en.png';

class Language {
    static language = localStorage.getItem("language") ?? "ro";

    static getLanguage = () => {
        return this.language;
    }

    static setLanguage = (language) => {
        this.language = language;
        localStorage.setItem("language", language);
    }

    static getFlags = () => {
        return {
            "ro": ROFlag,
            "en": ENFlag
        }
    }

    static getDictionary = () => {
        switch (this.language) {
            case "en":
                return ENDictionary;
            case "ro":
                return RODictionary;
            default:
                return ENDictionary;
        }
    }
}

export default Language;