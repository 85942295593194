/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Language, Layout } from "../common/utils";
import { Helmet } from 'react-helmet';
import {ImageUploader, PageHeader} from '../common';

import { editPage } from "../../redux/actions/page";
import axios from "axios";

const Index = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);

    const dictionary = Language.getDictionary(); // Get the current language dictionary

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    function chunkArray(array, chunkSize = 3) {
        return Array.from({ length: Math.ceil(array?.length / chunkSize) }, (_, index) =>
            array.slice(index * chunkSize, index * chunkSize + chunkSize)
        );
    }

    const [gallery, setGallery] = useState([]);
    const [image, setImage] = useState(null);

    useEffect(() => {
        setGallery(chunkArray(JSON.parse(getPageParameter("gallery"))));
    }, [page]);

    const saveImage = async () => {
        if (image == null) {
            return alert("Please upload an image before saving.");
        }

        const formData = new FormData();
        formData.append("image", image);

        await axios.post(`${process.env.REACT_APP_API_KEY}/files/options`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Token " + localStorage.getItem("token")
                }
        })
        .then(res => {
            dispatch(editPage({
                value: JSON.stringify([...JSON.parse(getPageParameter("gallery")), res.data.link]),
                name: "gallery"
            }))
        }).catch(err => { });
    }

    const removeImage = async (image) => {
        const gallery = JSON.parse(getPageParameter("gallery"));

        dispatch(editPage({
            value: JSON.stringify([...gallery.filter(item => item !== image)]),
            name: "gallery"
        }))
    }

    return (
        <>
            <Helmet>
                <title>{Language.language == "en" ? `Sufyi Embroidery. ${dictionary.gallery_title_page}` : `Sufyi Brodat. ${dictionary.gallery_title_page}`}</title>
                <meta name="description" content={dictionary.gallery_description_page} />
                <meta property="og:title" content={Language.language == "en" ? `Sufyi Embroidery. ${dictionary.gallery_title_page}` : `Sufyi Brodat. ${dictionary.gallery_title_page}`} />
                <meta property="og:description" content={dictionary.gallery_description_page} />
                <meta property="og:url" content="https://sufyi.com/gallery"></meta>
            </Helmet>
            <PageHeader page="Gallery" />
            <Layout>
                <center>
                    <h1 className="text-center fs-1 text-black bold landing__title m-auto w-fit">ARTWORK</h1>

                    <div className="mt-5">
                        {
                            gallery?.map((chunk, i) => (
                                <div key={i} className="gallery">
                                    {
                                        chunk?.map((image, i) => (
                                            <div className="d-flex flex-column gap-3">
                                                <img key={i} className="image" src={image} alt="Artwork" />

                                                {
                                                    account?.type == "master"  &&
                                                    <div className="button m-auto" onClick={() => removeImage(image)}>Remove</div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                        <div className="d-flex flex-column justify-content-center gap-3">
                            {
                                account?.type == "master"  &&
                                <>
                                    <ImageUploader value={image} setImage={(image) => {
                                       setImage(image);
                                    }} />

                                    <div className="button m-auto" onClick={saveImage}>Save</div>
                                </>
                            }
                        </div>
                    </div>

                </center>
            </Layout>
        </>
    );
};

export default Index;