import React from "react";
import { Link } from "react-router-dom";
import { Language } from "./utils";

const Slideshow = ({ image, imageType = 'video', landing }) => {
    const dictionary = Language.getDictionary();

    return (
        <div className="landing__banner">
        {
            imageType === 'video' ?
                <video autoPlay muted loop controls={false} className="background" src={image} alt='Sufyi Background.' />
            :
                <img src={image} alt='Sufyi Background.' className="background" />
        }
            <div className="content">
                {landing ?
                    <>
                        <div className="title">SUFYI Embroidery.</div>
                        <Link to="/products" className="button">{dictionary.shop_now}</Link>
                    </>
                    :
                    <>
                        <div className="title">Customize your outfit</div>
                        <Link to="/custom-merch" className="button">{dictionary.customize_now}</Link>
                    </>
                }
            </div>
        </div>
    );
}

export default Slideshow;