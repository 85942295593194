/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Layout } from "../common/utils";
import { Helmet } from "react-helmet";
import { Redirect, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SHA256 } from "crypto-js";
import { PageHeader } from "../common";
import { changeData } from "../../redux/actions/account";
import { Language } from "../common/utils";

const Index = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const dispatch = useDispatch();
  const account = useSelector((state) => state.account.data);
  const dictionary = Language.getDictionary();

  const [email, setEmail] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [cPassword, setConfirmedPassword] = useState(null);

  return (
    <>
      <Helmet>
        <title>{dictionary.account_page_title}</title>
      </Helmet>
      <PageHeader page={dictionary.account_page_header} />

      <Layout>
        {localStorage.getItem("visitor") === "true" ? (
          <Redirect to="/login" />
        ) : (
          <div
            className={
              isMobile
                ? "d-flex flex-column p-5 m-auto"
                : "d-flex flex-column m-auto"
            }
          >
            <br />
            <h2 className="text-start text-black">
              <b>
                {dictionary.account_page_greeting}, {account?.firstname}!
              </b>
            </h2>
            <div
              className={
                isMobile
                  ? "d-flex flex-column justify-content-start gap-2"
                  : "d-flex flex-row justify-content-start gap-2"
              }
            >
              <Link className="button" to="/orders">
                {dictionary.account_page_orders}
              </Link>
              {account?.type === "master" && (
                <>
                  <Link className="button" to="/promocodes">
                    {dictionary.account_page_manage_promocodes}
                  </Link>
                  <Link className="button" to="/insert">
                    {dictionary.account_page_add_products}
                  </Link>
                </>
              )}
            </div>
            <h2 className="text-start text-black mt-5">
              <b>{dictionary.account_info_title}</b>
            </h2>
            <ul className="d-flex flex-column justify-content-start list-unstyled">
              <li>
                <b>{dictionary.email}</b>
                <br />
                {account?.email}
              </li>
              <li>
                <b>{dictionary.first_name}</b>
                <br />
                {account?.firstname}
              </li>
              <li>
                <b>{dictionary.last_name}</b>
                <br />
                {account?.lastname}
              </li>
              <li>
                <b>{dictionary.phone_number}</b>
                <br />
                {account?.phone}
              </li>
            </ul>
            <br />
            <h2 className="text-start text-black mt-5">
              <b>{dictionary.update_account_info_title}</b>
            </h2>
            <center>
              <div
                className={
                  isMobile
                    ? "input__group d-flex flex-column justify-content-around w-100"
                    : "input__group d-flex flex-row justify-content-around w-100"
                }
              >
                <input
                  type="text"
                  placeholder={dictionary.first_name_placeholder}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
                <input
                  type="text"
                  placeholder={dictionary.last_name_placeholder}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
                <input
                  type="text"
                  placeholder={dictionary.email_placeholder}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="text"
                  placeholder={dictionary.phone_number_placeholder}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </center>
            <div
              className={isMobile ? "button m-auto" : "button w-25 mx-4"}
              onClick={() => {
                if (firstname && lastname && email && phone) {
                  dispatch(
                    changeData({
                      firstname,
                      lastname,
                      email,
                      phone,
                    })
                  );
                } else {
                  alert(dictionary.all_fields_required);
                }
              }}
            >
              {dictionary.apply_button}
            </div>
            <br />
            <h2 className="text-start text-black mt-5">
              <b>{dictionary.change_password_title}</b>
            </h2>
            <center>
              <div
                className={
                  isMobile
                    ? "input__group d-flex flex-column justify-content-around w-100"
                    : "input__group d-flex flex-row justify-content-around w-100"
                }
              >
                <input
                  type="password"
                  placeholder={dictionary.new_password_placeholder}
                  onChange={(text) => setPassword(text.target.value)}
                />
                <input
                  type="password"
                  placeholder={dictionary.confirm_password_placeholder}
                  onChange={(text) => setConfirmedPassword(text.target.value)}
                />
              </div>
            </center>
            <div
              className={isMobile ? "button m-auto" : "button w-25 mx-4"}
              onClick={() => {
                if (password === cPassword) {
                  dispatch(
                    changeData({
                      password: String(SHA256(password)),
                    })
                  );
                } else {
                  alert(dictionary.password_mismatch_alert);
                }
              }}
            >
              {dictionary.apply_button}
            </div>
            <br />
            <div
              className={isMobile ? "button m-auto" : "button w-25 mx-4"}
              onClick={() => {
                localStorage.removeItem("token");
                window.location.reload();
              }}
            >
              {dictionary.logout_button}
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Index;
