/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";

import { Language, Layout } from '../common/utils';

import { addToCart } from "../../redux/actions/cart";
import { addToWishlist } from "../../redux/actions/wishlist";
import { setConfiguratorCategory, setConfiguratorColor, setConfiguratorFace, setConfiguratorImage, setConfiguratorSex, setConfiguratorSize, setConfiguratorType } from "../../redux/actions/configurator";
import axios from 'axios';
import { editPage } from '../../redux/actions/page';
import { useLocation, Link } from 'react-router-dom';
import {PageHeader} from "../common";

import Logo from "../../assets/logo.png";

const EditablePrice = ({ accountType, parameterToEdit, indexes, price }) => {
    const dispatch = useDispatch();

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [priceState, setPriceState] = useState(price);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setPriceState(price);
    }, [price]);

    return (
        <div className="d-flex flex-row m-auto w-100">
            {
                editMode ?
                    <input className={isMobile ? "text-center w-75" : "text-center"} value={priceState} onChange={e => setPriceState(e.target.value)} />
                    :
                    <div>{Number(priceState).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</div>
            }

            {
                accountType == "master" &&
                (
                    editMode ?
                        <div className="d-flex flex-row m-auto">
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => {
                                    setEditMode(!editMode);

                                    let parameter = parameterToEdit.content;

                                    if (indexes.length == 3) {
                                        parameter[indexes[0]][indexes[1]][indexes[2]].price = Number(priceState.replace(",", "."));
                                    }
                                    else if (indexes.length == 2) {
                                        parameter[indexes[0]][indexes[1]].price = Number(priceState.replace(",", "."));
                                    }
                                    else {
                                        parameter[indexes[0]].price = Number(priceState.replace(",", "."));
                                    }

                                    dispatch(editPage({
                                        value: JSON.stringify({ ...parameter }),
                                        name: parameterToEdit.name
                                    }))
                                }}>done</span>
                            </div>
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => { setEditMode(!editMode); setPriceState(price) }}>close</span>
                            </div>
                        </div>
                        :
                        <div className="user-select-none">
                            <span className="material-icons-outlined pointer fs-5" onClick={() => setEditMode(!editMode)}>edit</span>
                        </div>
                )
            }
        </div>
    )
}

const EditableText = ({ accountType, parameterToEdit, indexes, text }) => {
    const dispatch = useDispatch();

    const [textState, setTextState] = useState(text);
    const [editMode, setEditMode] = useState(false);

    return (
        <div className="d-flex flex-row input__group m-auto justify-content-start w-100">
            {
                editMode ?
                    <input className="text-center" value={textState} onChange={e => setTextState(e.target.value)} />
                    :
                    <div className="whitespace-break-spaces" dangerouslySetInnerHTML={{ __html: textState }}></div>
            }

            {
                accountType == "master" &&
                (
                    editMode ?
                        <div className="d-flex flex-row m-auto">
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => {
                                    setEditMode(!editMode);

                                    let parameter = parameterToEdit.content;

                                    if (indexes.length == 4) {
                                        parameter[indexes[0]][indexes[1]][indexes[2]][indexes[3]] = textState.replace(/\n/g, "<br />").replace(/\\n/g, "<br />").replace(/\\/g, "");
                                    }
                                    else if (indexes.length == 3) {
                                        parameter[indexes[0]][indexes[1]][indexes[2]] = textState.replace(/\n/g, "<br />").replace(/\\n/g, "<br />").replace(/\\/g, "");
                                    }
                                    else if (indexes.length == 2) {
                                        parameter[indexes[0]][indexes[1]] = textState.replace(/\n/g, "<br />").replace(/\\n/g, "<br />").replace(/\\/g, "");
                                    }
                                    else {
                                        parameter[indexes[0]] = textState.replace(/\n/g, "<br />").replace(/\\n/g, "<br />").replace(/\\/g, "");
                                    }

                                    dispatch(editPage({
                                        value: JSON.stringify({ ...parameter }),
                                        name: parameterToEdit.name
                                    }))
                                }}>done</span>
                            </div>
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => { setEditMode(!editMode); setTextState(text) }}>close</span>
                            </div>
                        </div>
                        :
                        <div className="user-select-none">
                            <span className="material-icons-outlined pointer fs-5" onClick={() => setEditMode(!editMode)}>edit</span>
                        </div>
                )
            }
        </div>
    )
}

const Index = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const dispatch = useDispatch();

    const location = useLocation();

    const dictionary = Language.getDictionary(); // Get the current language dictionary

    const configurator = useSelector(state => state.configurator);
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);

    const hiddenFileInput = useRef(null);
    const reader = new FileReader();

    const [imageClient, setImageClient] = useState(null);
    const [productTab, setProductTab] = useState(null);
    const [products, setProducts] = useState({});

    reader.onloadend = e => {
        dispatch(setConfiguratorImage(reader.result))
    };

    useEffect(() => {
        if (imageClient instanceof Blob) {
            reader.readAsDataURL(imageClient);

            const formData = new FormData();
            formData.append("image", imageClient);

            axios.post(`${process.env.REACT_APP_API_KEY}/files/options`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": "Token " + localStorage.getItem("token")
                    }
                })
                .then(res => {
                    dispatch(setConfiguratorImage(res.data.link));
                }).catch(err => { });

        }
    }, [imageClient]);

    useEffect(() => {
        if (!page.inLoading) {
            setProducts(getProducts());
            dispatch(setConfiguratorCategory("clothes"));
        }
    }, [page]);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name === parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const getProducts = () => {
        return JSON.parse(getPageParameter("product_types"));
    }

    const getImageOfProduct = () => {
        if (configurator.category == "clothes") {
            return configurator.sex != "none" && configurator.type != "none" && configurator.face != "none" && configurator.color != "none" ? require(`../../assets/products/${configurator.category}/${configurator.sex}/${configurator.type}/${configurator.face}-${configurator.color}.png`).default : Logo;
        }
        return Logo;
    }

    useEffect(() => {
        if (JSON.stringify(products) != "{}") {
            dispatch(setConfiguratorColor(products?.[configurator.category]?.[configurator?.sex]?.[configurator.type]?.colors[0]));
        }
    }, [configurator.category, configurator.type, location, products]);

    useEffect(() => {
        setProductTab(null);
    }, [configurator]);

    return (
        <>
            <Helmet>
                <title>{Language.language == "en" ? `Sufyi Embroidery. ${dictionary.configurator_title_page}` : `Sufyi Brodat. ${dictionary.configurator_title_page}`}</title>
                <meta name="description" content={dictionary.configurator_description_page} />
                <meta property="og:title" content={Language.language == "en" ? `Sufyi Embroidery. ${dictionary.configurator_title_page}` : `Sufyi Brodat. ${dictionary.configurator_title_page}`} />
                <meta property="og:description" content={dictionary.configurator_description_page} />
                <meta property="og:url" content="https://sufyi.com/custom-merch"></meta>
            </Helmet>
            <PageHeader page="Custom Merch" />

            {
                JSON.stringify(products) != "{}" && <>
                    <Layout className="my-5">
                        <h1 className="text-center fs-1 text-black bold landing__title m-auto mt-5 w-fit">CUSTOM MERCH</h1>

                        <div className='configurator my-5'>
                            <div className='container preview'>
                                <img className={`image`} src={getImageOfProduct()} alt="base" />
                                <div className={`color--overlay ${configurator.color}`}></div>

                                {
                                    configurator.category != "none" && configurator.type != "none" && configurator.sex != "none" && configurator.face != "none" && <>
                                        <div className={`border ${configurator.type == "hoodie" && configurator.face == "back" ? "client__img--hoodie-back" : ""}`}></div>

                                        {
                                            configurator.image != "none" &&
                                            <img className={`client__img ${configurator.type == "hoodie" && configurator.face == "back" ? "client__img--hoodie-back" : ""} ${configurator.category == "pillow" ? "client__img--accessories" : ""} ${configurator.category == "pack" ? "client__img--pack" : ""}`} src={configurator.image} alt="preview config. product" />
                                        }
                                    </>
                                }

                                {
                                    configurator.category != "none" && configurator.color != "none" && configurator.category != "clothes" && configurator.category != "animalut" && <>
                                        <div className={`border border--${configurator.category}`}></div>

                                        {
                                            configurator.category == "pack" &&
                                            <>
                                                <div className={`border border--${configurator.category}1`}></div>
                                                <div className={`border border--${configurator.category}2`}></div>
                                                <img className={`client__img client__img--${configurator?.category}1`} src={configurator.image} alt="preview config. product" />
                                                <img className={`client__img client__img--${configurator?.category}2`} src={configurator.image} alt="preview config. product" />
                                            </>
                                        }

                                        {
                                            configurator.image != "none" &&
                                            <img className={`client__img client__img--${configurator?.category}`} src={configurator.image} alt="preview config. product" />
                                        }
                                    </>
                                }

                                <div className='disclaimer'>We recommend image with ".png" extension.</div>
                            </div>

                            <div className='choices d-flex flex-column justify-content-start gap-3'>
                                <div className={isMobile ? 'd-flex flex-row flex-wrap justify-content-center gap-2 mt-5' : 'd-flex flex-row justify-content-center gap-2'}>
                                    {
                                        products?.[configurator?.category] && Object.keys(products?.[configurator?.category])?.map((value, idx) => value != "none" && value != "name" && (
                                            <div key={idx} className={`button ${value == configurator.sex ? "button--selected" : ""}`} onClick={() => { dispatch(setConfiguratorSex(value)) }}>
                                                {
                                                    products?.[configurator.category]?.[value]?.name
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                {
                                     configurator.sex != "none" && <>
                                        <div className={isMobile ? 'd-flex flex-row flex-wrap justify-content-center gap-2 mt-5' : 'd-flex flex-row justify-content-center gap-2'}>
                                            {
                                                Object.keys(products?.[configurator?.category]?.[configurator?.sex])?.map((value, idx) => value != "none" && value != "name" && (
                                                    <div key={idx} className={`button ${value == configurator.type ? "button--selected" : ""}`} onClick={() => { dispatch(setConfiguratorType(value)); dispatch(setConfiguratorColor(products?.[configurator.category]?.[configurator?.sex]?.[value]?.colors[0])) }}>
                                                        {
                                                            products?.[configurator.category]?.[configurator?.sex]?.[value]?.name
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    configurator.type != "none" && <>
                                        <div className={isMobile ? 'd-flex flex-column mt-5' : 'd-flex flex-column'}>
                                            <div className='text-center text-black'>Choose the placement of the drawing:</div>
                                            <div className={isMobile ? 'd-flex flex-row flex-wrap justify-content-center gap-2 mt-2' : 'd-flex flex-row justify-content-center gap-2'}>
                                                {
                                                    products?.[configurator.category]?.[configurator?.sex]?.[configurator.type]?.faces?.map((value, idx) => value != "none" && value != "name" && (
                                                        <div key={idx} className={`button ${value == configurator.face ? "button--selected" : ""}`} onClick={() => dispatch(setConfiguratorFace(value))}>
                                                            {
                                                                value == "front" ? "Front" : "Back"
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    configurator.type != "none" && <>
                                        <div className={isMobile ? 'product__container d-flex flex-column mt-5' : 'product__container d-flex flex-column'}>
                                            <div className='text-center text-black'>Choose color size:</div>

                                            <div className={isMobile ? 'value colors justify-content-center flex-wrap' : 'value colors justify-content-center'}>
                                                {
                                                    products?.[configurator.category]?.[configurator?.sex]?.[configurator.type]?.colors?.map((color, idx) =>
                                                        <div key={idx} className={(configurator.color == color ? ' selected' : '')}>
                                                            <div className={`color ${color}`} onClick={() => dispatch(setConfiguratorColor(color))}>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    configurator.type != "none" && <>
                                        <div className={isMobile ? 'product__container d-flex flex-column mt-5' : 'product__container d-flex flex-column'}>
                                            <div className='text-center text-black'>Choose your size:</div>

                                            <div className={isMobile ? 'value sizes justify-content-center flex-wrap' : 'value sizes justify-content-center'}>
                                                {
                                                    products?.[configurator.category]?.[configurator?.sex]?.[configurator.type]?.sizes.map((size, idx) => (
                                                         <div key={idx} className={'size' + (configurator?.size.name == size.name ? ' active' : '')}
                                                             onClick={() => {
                                                                  dispatch(setConfiguratorSize(size))
                                                             }}
                                                         >
                                                             <div className="value">
                                                                 {size.name}
                                                             </div>
                                                         </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                <input
                                    ref={hiddenFileInput}
                                    accept="image/*"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(event) => {
                                        if (event.target.files[0]) {
                                            setImageClient(event.target.files[0]);
                                        }
                                    }}
                                />
                                <div className="button mx-auto mt-5" onClick={() => hiddenFileInput.current.click()}>Upload your image here.</div>

                                <div className='text-black text-center mt-5'>The period of making can vary between 5 and 7 working days.</div>
                            </div>
                        </div>

                        <div className={isMobile ? 'd-flex flex-column justify-content-between gap-5' : 'd-flex flex-row justify-content-between px-5 mx-5'}>
                            <div className='flex-column justify-content-center align-items-center gap-1'>
                                {
                                    products?.[configurator.category]?.[configurator.sex]?.[configurator.type]?.price &&
                                    <div className='button button--buy configurator__price mx-2'>
                                        <EditablePrice accountType={account?.type} parameterToEdit={{ name: "product_types", content: products }} indexes={[configurator.category, configurator.sex, configurator.type]} price={products?.[configurator.category]?.[configurator.sex]?.[configurator.type]?.price} />
                                    </div>
                                }
                            </div>

                            <div className='d-flex flex-row justify-content-center gap-4 mx-2'>
                                <div className="button button--buy mx-0" onClick={() => {
                                    if (configurator.category == "none") {
                                        alert("Please, fill up all fields.");
                                        return;
                                    }

                                    if (configurator.category == "clothes") {
                                        if (configurator.type == "none" || configurator.face == "none" || configurator.color == "none" || configurator.size == "none") {
                                            alert("Please, fill up all fields.");
                                            return;
                                        }
                                    }

                                    const product_id = Number("" +
                                        Object.keys(products).indexOf(configurator.category) + 1000
                                    )
                                    dispatch(addToCart(product_id, { ...configurator, price: products?.[configurator.category]?.[configurator.type]?.price, custom: true }, 1));
                                }}>
                                    <span className="material-icons-outlined icon">
                                        local_mall
                                    </span>
                                    Add to cart
                                </div>

                                <div className="button button--buy mx-0" onClick={() => {
                                    if (configurator.type == "none" || configurator.face == "none" || configurator.color == "none" || configurator.size == "none") {
                                        alert("Please, fill up all fields.");
                                        return;
                                    }

                                    const product_id = Number("" +
                                        Object.keys(products).indexOf(configurator.category) + 1000
                                    )

                                    dispatch(addToWishlist(product_id, { ...configurator }));

                                }}>
                                    <span className="material-icons-outlined icon">
                                        favorite
                                    </span>
                                    Add to wishlist
                                </div>
                            </div>
                        </div>

                        {
                            configurator.category != "none" && configurator.type != "none" && configurator.sex != "none" &&
                            <div className='d-flex flex-column gap-2 mt-5'>
                                <div className='d-flex flex-column justify-content-center w-100 gap-5'>
                                    <div className={isMobile ? 'mx-2 mb-0 card' : 'mx-5 mb-0 card'}>
                                        <div className='bg-black text-white w-100 p-3 d-flex flex-row justify-content-between rounded-pill' onClick={() => setProductTab(productTab != 1 ? 1 : 0)}>
                                            <div>Description</div>

                                            <div className="pointer material-icons-outlined">

                                                {
                                                    productTab == 1 ?
                                                        "keyboard_arrow_up"
                                                        :
                                                        "keyboard_arrow_down"
                                                }
                                            </div>
                                        </div>

                                        {
                                            productTab == 1 &&
                                            <div className='p-3'>
                                                <EditableText accountType={account?.type} parameterToEdit={{ name: "product_types", content: products }} indexes={[configurator.category, configurator.sex, configurator.type, "description"]} text={products[configurator.category][configurator.sex][configurator.type].description} />

                                                <Link to="/size-guide" className="text-center btn btn-link">Click here to review the Size Guide.</Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </Layout>
                </>
            }
        </>
    );
};

export default Index;